import { render, staticRenderFns } from "./CpdList.vue?vue&type=template&id=f389c0e6&scoped=true"
import script from "./CpdList.vue?vue&type=script&lang=js"
export * from "./CpdList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f389c0e6",
  null
  
)

export default component.exports