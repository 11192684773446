<template>
  <v-container class="mt-5">
    <v-data-table :headers="headers" :items="ledgers" dense class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">My Statement</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="item.externalDocumentNo"
          outlined
          small
          color="primary"
          @click="
            $root.routeTo({
              name: item.documentType === 'Invoice' ? 'invoice' : 'receipt',
              params: {
                code:
                  item.documentType === 'Invoice'
                    ? item.externalDocumentNo
                    : item.documentNo,
              },
            })
          "
        >
          <v-icon color="primary" left small> mdi-file </v-icon>
          <span class="text-capitalize">View {{ item.documentType }}</span>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "BillingList",

  data: () => ({
    headers: [
      { text: "Document No", value: "documentNo" },
      { text: "Document Type", value: "documentType" },
      { text: "Description", value: "description" },
      { text: "Amount", value: "amountLCY" },
      { text: "Due Date", value: "dueDate" },
      { text: "Posting Date", value: "postingDate" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  mounted() {
    this.$store.dispatch("Billing/getLedgers");
  },

  computed: {
    ledgers() {
      return this.$store.getters["Billing/ledger"];
    },
  },
};
</script>

<style scoped></style>
