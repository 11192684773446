import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import Upgrade from "./views/Upgrade";
import UpgradeList from "./components/UpgradeList";
import UpgradeCard from "./components/UpgradeCard";
import UpgradeChecklist from "./components/UpgradeChecklist";
import UpgradeDocument from "./components/UpgradeDocument";
import UpgradePlan from "./components/UpgradePlan";
import Auth from "../../router/middleware/Auth";
import acceptanceLetter from "./components/acceptanceLetter";

export default [
  {
    path: "/upgrade",
    component: DashboardLayout,
    children: [
      {
        path: "",
        components: { view: Upgrade },
        children: [
          {
            path: "",
            name: "upgradeList",
            components: { view: UpgradeList },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "plan",
            name: "upgradePlan",
            components: { view: UpgradePlan },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "checklist/:code",
            name: "UpgradeChecklist",
            components: { view: UpgradeChecklist },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "card",
            name: "UpgradeCard",
            components: { view: UpgradeCard },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "document/:code",
            name: "upgradeDocument",
            components: { view: UpgradeDocument },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
          {
            path: "acceptance-letter/:code",
            name: "acceptanceLetter",
            components: { view: acceptanceLetter },
            meta: { middleware: [Auth], breadcrumb: "Dashboard" },
          },
        ],
      },
    ],
  },
];
