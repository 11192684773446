import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-5"},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.licenses,"sort-by":"calories","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.minorSettings.license_alternative_caption)+" Applications ")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","depressed":"","outlined":"","dark":"","to":{ name: 'licenseCard' }}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-playlist-plus")]),_vm._v(" New "+_vm._s(_vm.minorSettings.license_alternative_caption)+" ")],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"x-small":"","outlined":"","color":"secondary","to":{
          name: 'licenseDocument',
          params: { code: item.licenseApplicationNo },
        }}},[_vm._v(" view "),_c(VIcon,{attrs:{"color":"secondary","right":"","small":""}},[_vm._v(" mdi-arrow-right ")])],1),(item.proFormaRef !== '')?_c(VBtn,{staticClass:"ml-2",attrs:{"x-small":"","outlined":"","color":"secondary"},on:{"click":function($event){return _vm.$root.routeTo({
            name: 'invoice',
            params: { code: item.licenseApplicationNo },
          })}}},[_vm._v(" "+_vm._s(item.receiptNo === "" ? "Proforma" : "Invoice")+" "),_c(VIcon,{attrs:{"color":"secondary","right":"","small":""}},[_vm._v(" mdi-file-document ")])],1):_vm._e(),(item.receiptNo === '' && item.licenseBase64 !== '')?_c(VBtn,{staticClass:"ml-2",attrs:{"x-small":"","outlined":"","color":"secondary"},on:{"click":function($event){return _vm.pay(item)}}},[_c('span',{staticClass:"text-white"},[_vm._v(" Pay Now "),_c(VIcon,[_vm._v("mdi-cash-multiple")])],1)]):_vm._e(),(item.receiptNo !== '')?_c(VBtn,{staticClass:"mlm-2",attrs:{"x-small":"","outlined":"","color":"secondary"},on:{"click":function($event){return _vm.$root.routeTo({
            name: 'receipt',
            params: { code: item.receiptNo },
          })}}},[_c('span',{staticClass:"text-white"},[_vm._v(" Receipt "),_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-receipt")])],1)]):_vm._e()]}}])}),_c('Checkout')],1)}
var staticRenderFns = []

export { render, staticRenderFns }