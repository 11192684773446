import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"mx-auto users--top",attrs:{"flat":""}},[_c(VCardTitle,[_c('span',[_vm._v("All Users")]),_c(VTextField,{staticClass:"mx-3",attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCardText,[_c(VDataTable,{staticClass:"elevation-0 users--table",attrs:{"headers":_vm.headers,"items":_vm.filteredItems},scopedSlots:_vm._u([{key:"item.email_verified_at",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"label":"","color":item.email_verified_at ? 'green lighten-4' : 'red lighten-4'}},[(item.email_verified_at)?_c(VIcon,{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-close")]),_c('span',{class:item.email_verified_at
                  ? 'green--text text--darken-4'
                  : 'red--text text--darken-4'},[_vm._v(_vm._s(item.email_verified_at ? "Verified" : "Not Verified"))])],1)]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c(VBtn,{staticClass:"mr-2 blue white--text text-capitalize",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" edit ")],1),_c(VBtn,{staticClass:"mr-2 error text-capitalize",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" delete ")],1),_c(VBtn,{staticClass:"mr-2 indigo white--text text-capitalize",attrs:{"small":""},on:{"click":function($event){return _vm.sendPasswordReset(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-send-circle-outline ")]),_vm._v(" Send Password Reset Link ")],1),(!item.is_admin && !item.is_approver)?_c(VBtn,{staticClass:"primary text-capitalize",attrs:{"small":""},on:{"click":function($event){return _vm.loginAs(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-login-variant ")]),_vm._v(" login as profile "+_vm._s(item.profile_id)+" ")],1):_vm._e()],1)]}}])})],1)],1),_c('AdminUserDialog',{attrs:{"dialog":_vm.dialog,"user":_vm.user},on:{"close":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }