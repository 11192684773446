import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-5"},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ledgers,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,{staticClass:"text-h6"},[_vm._v("My Statement")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VSpacer)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.externalDocumentNo)?_c(VBtn,{attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$root.routeTo({
            name: item.documentType === 'Invoice' ? 'invoice' : 'receipt',
            params: {
              code:
                item.documentType === 'Invoice'
                  ? item.externalDocumentNo
                  : item.documentNo,
            },
          })}}},[_c(VIcon,{attrs:{"color":"primary","left":"","small":""}},[_vm._v(" mdi-file ")]),_c('span',{staticClass:"text-capitalize"},[_vm._v("View "+_vm._s(item.documentType))])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }