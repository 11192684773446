<template>
  <v-container class="mt-5">
    <v-tabs v-model="tab" active-class="primary lighten-5">
      <v-tab>Upcoming Events</v-tab>
      <v-tab>Booked Events</v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-title class="">
              <v-spacer />
              <v-btn-toggle
                color="primary"
                v-model="displayType"
                mandatory
                class="mr-4"
                borderless
                active-class="primary--text"
              >
                <v-tooltip bottom v-for="(item, i) in displayTypes" :key="i">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      v-on="on"
                      v-bind="attrs"
                      :value="item.value"
                      :title="item.text"
                    >
                      {{ $vuetify.breakpoint.mdAndUp ? "" : item.text }}
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.text }}</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-card-title>
            <EventCards
              v-if="displayType === 'grid'"
              :events="events"
              :eventLink="url"
            />
            <EventListItems
              v-if="displayType === 'list'"
              :events="events"
              :eventLink="url"
            />
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-card-text class="pb-0">
              <v-data-table
                :headers="headers"
                :items="bookings"
                dense
                class="elevation-1"
              >
                <template v-slot:item.eventDescription="{ item }">
                  <span>{{ item.eventDescription }}</span>
                </template>
                <template v-slot:item.location="{ item }">
                  <span>{{ item.location }}</span>
                </template>
                <template v-slot:item.paid="{ item }">
                  <v-icon :color="item.paid ? 'green' : 'error'">
                    {{ item.paid ? "mdi-check" : "mdi-close" }}
                  </v-icon>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip
                    label
                    small
                    :color="`${bookingStatusColor(item.status)} lighten-5`"
                    :class="`${bookingStatusColor(
                      item.status
                    )}--text text--darken-4 text-capitlize`"
                  >
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    v-if="item.isActive"
                    outlined
                    x-small
                    color="secondary"
                    :href="editEvent(encodeRoute(item.bookingNo))"
                    target="_blank"
                  >
                    view
                    <v-icon color="secondary" right small>
                      mdi-arrow-right
                    </v-icon>
                  </v-btn>

                  <v-btn
                    v-if="item.paid"
                    outlined
                    x-small
                    color="secondary"
                    class="ml-2"
                    @click="
                      $root.routeTo({
                        name: 'receipt',
                        params: { code: item.receiptNo },
                      })
                    "
                  >
                    <v-icon color="secondary" small> mdi-file </v-icon>
                    Receipt
                  </v-btn>

                  <v-btn
                    v-if="item.billed"
                    outlined
                    x-small
                    class="ml-2"
                    color="primary"
                    @click="
                      $root.routeTo({
                        name: 'invoice',
                        params: { code: item.proformaInvoiceNo },
                      })
                    "
                  >
                    <v-icon color="primary" small>mdi-file-document</v-icon>
                    Invoice
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>
<script>
import helper from "../../../utils/helpers/helper";
import RouterMixin from "../../../router/RouterMixin";
import EventCards from "./EventCards";
import EventListItems from "./EventListItems.vue";
import { eventListing } from "@/environment/environment";

export default {
  name: "EventList",
  components: {
    EventCards,
    EventListItems,
  },
  data() {
    return {
      url: process.env.VUE_APP_EVENTS,
      tab: null,
      displayType: eventListing,
      displayTypes: [
        { text: "List", value: "list", icon: "format_list_bulleted" },
        { text: "Grid", value: "grid", icon: "grid_view" },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Events/getEvents");
      v.$store.dispatch("Events/getBookings");
    });
  },
  mixins: [RouterMixin],
  computed: {
    headers() {
      return [
        { text: "No", value: "bookingNo" },
        { text: "Event", value: "eventDescription" },
        { text: "Location", value: "location" },
        { text: "Paid", value: "paid" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ];
    },
    bookings() {
      return this.$store.getters["Events/eventsGetters"]("eventBookings");
    },
    events() {
      return this.$store.getters["Events/eventsGetters"]("events");
    },
    actions: function () {
      return [
        {
          text: "Edit",
          disabled: false,
          action: (item) => {
            this.select(item);
          },
        },
      ];
    },
  },
  methods: {
    bookingStatusColor(status) {
      if (status) {
        return helper.status()[status.toLowerCase()];
      }
    },
    editEvent(event) {
      return process.env.VUE_APP_EVENTS + `booking-view/${event}`;
    },
  },
};
</script>
<style scoped>
@import url("../styles.css");
</style>
