import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-5"},[_c(VTabs,{attrs:{"active-class":"primary lighten-5"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,[_vm._v("Upcoming Events")]),_c(VTab,[_vm._v("Booked Events")]),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{},[_c(VSpacer),_c(VBtnToggle,{staticClass:"mr-4",attrs:{"color":"primary","mandatory":"","borderless":"","active-class":"primary--text"},model:{value:(_vm.displayType),callback:function ($$v) {_vm.displayType=$$v},expression:"displayType"}},_vm._l((_vm.displayTypes),function(item,i){return _c(VTooltip,{key:i,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":"","value":item.value,"title":item.text}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? "" : item.text)+" "),_c(VIcon,[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])])}),1)],1),(_vm.displayType === 'grid')?_c('EventCards',{attrs:{"events":_vm.events,"eventLink":_vm.url}}):_vm._e(),(_vm.displayType === 'list')?_c('EventListItems',{attrs:{"events":_vm.events,"eventLink":_vm.url}}):_vm._e()],1)],1),_c(VTabItem,[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"pb-0"},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.bookings,"dense":""},scopedSlots:_vm._u([{key:"item.eventDescription",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.eventDescription))])]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.location))])]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"color":item.paid ? 'green' : 'error'}},[_vm._v(" "+_vm._s(item.paid ? "mdi-check" : "mdi-close")+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{class:((_vm.bookingStatusColor(
                    item.status
                  )) + "--text text--darken-4 text-capitlize"),attrs:{"label":"","small":"","color":((_vm.bookingStatusColor(item.status)) + " lighten-5")}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [(item.isActive)?_c(VBtn,{attrs:{"outlined":"","x-small":"","color":"secondary","href":_vm.editEvent(_vm.encodeRoute(item.bookingNo)),"target":"_blank"}},[_vm._v(" view "),_c(VIcon,{attrs:{"color":"secondary","right":"","small":""}},[_vm._v(" mdi-arrow-right ")])],1):_vm._e(),(item.paid)?_c(VBtn,{staticClass:"ml-2",attrs:{"outlined":"","x-small":"","color":"secondary"},on:{"click":function($event){return _vm.$root.routeTo({
                      name: 'receipt',
                      params: { code: item.receiptNo },
                    })}}},[_c(VIcon,{attrs:{"color":"secondary","small":""}},[_vm._v(" mdi-file ")]),_vm._v(" Receipt ")],1):_vm._e(),(item.billed)?_c(VBtn,{staticClass:"ml-2",attrs:{"outlined":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.$root.routeTo({
                      name: 'invoice',
                      params: { code: item.proformaInvoiceNo },
                    })}}},[_c(VIcon,{attrs:{"color":"primary","small":""}},[_vm._v("mdi-file-document")]),_vm._v(" Invoice ")],1):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }