import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c(VCard,{staticClass:"my-5"},[_c(VCardTitle,[_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Back ")],1),_c(VSpacer),_c(VBtn,{attrs:{"small":"","color":"secondary"},on:{"click":function($event){_vm.downloadFile(_vm.receipt, ("RECEIPT-" + (_vm.currentDate()) + ".pdf"))}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Download ")],1)],1),(_vm.receipt === undefined)?_c(VSheet,{staticClass:"pa-3",attrs:{"color":"`grey"}},[_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"max-width":"300","type":"card"}})],1):_vm._e(),(
            (_vm.receipt !== undefined && _vm.receipt.includes('Invalid')) ||
            _vm.receipt === ''
          )?_c(VSheet,{staticClass:"pa-3",attrs:{"color":"`grey"}},[_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"max-width":"300","type":"card"}}),_c('span',[_vm._v("Invalid receipt No.")])],1):_vm._e(),_vm._l((_vm.pageCount(_vm.receipt)),function(i){return _c(VCardText,{key:i,staticClass:"elevation-1 mb-1"},[(_vm.receipt)?_c('pdf',{staticStyle:{"width":"100%"},attrs:{"src":("data:application/pdf;base64," + _vm.receipt),"page":i}}):_vm._e(),_c('p',{attrs:{"else":""}},[_vm._v("No Receipt Found!")])],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }