import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(
      typeof _vm.document !== 'undefined' && _vm.document.length !== 0 && !_vm.loading
    )?_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c(VCard,{staticClass:"my-5"},[_c(VCardTitle,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.downloadFile(_vm.document, _vm.fileName)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Download ")],1)],1),_c('pdf',{staticStyle:{"width":"100%"},attrs:{"src":("data:application/pdf;base64," + _vm.document)}})],1)],1)],1):(_vm.showGoodStandingMessage)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c(VCard,{staticClass:"my-5",attrs:{"flat":""}},[_c(VCardText,{staticClass:"text-center"},[_c('h2',[_vm._v("Oops,")]),_vm._v(" something went wrong. ")])],1)],1)],1):_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c(VCard,{staticClass:"my-5"},[_c(VCardTitle,[_c(VSpacer),_c(VSkeletonLoader,{attrs:{"type":"button"}})],1),_c('div',[_c(VSkeletonLoader,{attrs:{"type":"card"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }